import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import Layout from 'components/shared/Layout';
import SEO from 'components/shared/Seo';

const BannerWork = loadable(() => import('../components/organisms/BannerWork'));
const NextWorkCaseStudy = loadable(() =>
  import('../components/organisms/NextWorkCaseStudy')
);

const findSimilarBgColor = (widgetsData, widget, index) => {
  if (index <= 0) return;

  const prevItem = widgetsData[index - 1];

  if (
    prevItem.background_fill &&
    widget.background_fill &&
    prevItem.background_color === widget.background_color
  ) {
    widget.same_color = true;
  }
};

const WorkTemplate = ({ data }) => {
  const { allWordpressWpAppWork, allWordpressWpAppWorkTag } = data;

  const tags = allWordpressWpAppWorkTag.edges;

  const currentPageData = allWordpressWpAppWork.edges[0].node;
  const currentPageWidgetsData = currentPageData.acf.blocks_app_work || [];
  const breadcrumbsData = {
    data: {
      title: currentPageData.title,
      path: currentPageData.path,
    },
    parent: { title: 'Work', path: '/works' },
  };

  let pageComponents = [];
  currentPageWidgetsData.forEach((widget, index) => {
    switch (widget.__typename) {
      case 'WordPressAcf_text':
        const WidgetWorkText = loadable(() =>
          import('../components/molecules/WidgetWorkText')
        );
        return pageComponents.push(
          <WidgetWorkText data={widget} key={index} />
        );
      case 'WordPressAcf_image':
        const WidgetWorkImage = loadable(() =>
          import('../components/molecules/WidgetWorkImage')
        );
        return pageComponents.push(
          <WidgetWorkImage data={widget} key={index} />
        );
      case 'WordPressAcf_video':
        const WidgetWorkVideo = loadable(() =>
          import('../components/molecules/WidgetWorkVideo')
        );
        pageComponents.push(<WidgetWorkVideo data={widget} key={index} />);
        break;

      case 'WordPressAcf_images':
        const WidgetWorkImages = loadable(() =>
          import('../components/molecules/WidgetWorkImages')
        );
        return pageComponents.push(
          <WidgetWorkImages data={widget} key={index} />
        );

      default:
        return;
    }

    findSimilarBgColor(currentPageWidgetsData, widget, index);
  });

  return (
    <Layout footerType={'full'}>
      <SEO yoastMeta={currentPageData.yoast_meta} url={currentPageData.link} />
      <BannerWork
        data={currentPageData}
        tagsData={tags}
        breadcrumbsData={breadcrumbsData}
      />
      {pageComponents}
      <NextWorkCaseStudy data={currentPageData} />
    </Layout>
  );
};

export default WorkTemplate;

export const query = graphql`
  query($id: String!) {
    allWordpressWpAppWork(filter: { id: { eq: $id } }) {
      ...AllWordpressWpAppWorkFragment
    }
    allWordpressWpAppWorkTag {
      ...AllWordpressWpAppWorkTagFragment
    }
  }
`;
